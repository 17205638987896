import { createSlice, createSelector, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { IRmOpList, bookRoomProps, rmPckProps } from "interface/book";

export interface bookState {
  step: number;
  isPackageModal: {
    isOpen: boolean;
    rmNo?: string;
  };
  isDetailModal: {
    isOpen: boolean;
    rmPdNo?: string;
  };
  isSearch: boolean;
  isBook: boolean;
  isBookSheetFull: boolean;
  rmOpList: IRmOpList[];

  bookInfo: {
    rmCnt: number;
    adltCnt: number;
    chldCnt: number;
    proCd: string;

    isAbleproCd: boolean;
    dates: string | number;
    totalPrice: string | number;
    rmPck: IRmOpList | null;
  };

  dateRanges: string[];
  dateSelects: string[];
  timeSelects: number[];

  checkDate: {
    in: boolean;
    out: boolean;
  };
}

const initialState: bookState = {
  step: 1,
  isPackageModal: {
    isOpen: false,
    rmNo: "",
  },
  isDetailModal: {
    isOpen: false,
    rmPdNo: "",
  },
  isSearch: false,
  isBook: false,
  isBookSheetFull: false,
  rmOpList: [],

  bookInfo: {
    rmCnt: 1,
    adltCnt: 1,
    chldCnt: 0,
    proCd: "",
    isAbleproCd: false,
    dates: "",
    totalPrice: "",
    rmPck: null,
  },

  dateRanges: [],
  dateSelects: [],
  timeSelects: [],

  checkDate: {
    in: false,
    out: false,
  },
};

export const bookSlice = createSlice({
  name: "book",
  initialState,
  reducers: {
    /*********           ***********/
    setBookInfo: (
      state,
      { payload }: PayloadAction<Partial<bookState["bookInfo"]>>
    ) => {
      const info = current(state.bookInfo);
      state.bookInfo = {
        ...info,
        ...payload,
      };
    },

    setResetBookInfo: (state, { payload }: PayloadAction) => {
      state.bookInfo = {
        rmCnt: 1,
        adltCnt: 2,
        chldCnt: 0,
        proCd: "",
        isAbleproCd: false,
        dates: "",
        totalPrice: "",
        rmPck: null,
      };
      // state.step = 1;
    },

    setIsBookSheetFull: (state, { payload }: PayloadAction<boolean>) => {
      state.isBookSheetFull = payload;
    },

    setResetDate: (state, { payload }: PayloadAction) => {
      state.dateSelects = [];
      state.dateRanges = [];
      localStorage.removeItem("dates");
    },

    setTimeSelect: (state, { payload }: PayloadAction<number>) => {
      const times = current(state.timeSelects);
      const dpTimes = times.filter((time) => {
        return time !== payload;
      });

      state.timeSelects =
        dpTimes.length === times.length ? [...times, payload] : dpTimes;
    },
    setIsBook: (state, { payload }: PayloadAction<boolean>) => {
      state.isBook = payload;
    },
    setRmOpList: (state, { payload }: PayloadAction<bookState["rmOpList"]>) => {
      state.rmOpList = payload;
    },

    /*********           ***********/

    setStep: (state, { payload }: PayloadAction<bookState["step"]>) => {
      state.step = payload;
    },
    setrmPck: (state, { payload }: PayloadAction<rmPckProps | null>) => {
      // state.bookInfo. = payload;
    },
    setIsSearch: (state, { payload }: PayloadAction<bookState["isSearch"]>) => {
      state.isSearch = payload;
    },

    setIsPackageModal: (
      state,
      { payload }: PayloadAction<bookState["isPackageModal"]>
    ) => {
      state.isPackageModal = payload;
    },

    setIsDetailModal: (
      state,
      { payload }: PayloadAction<bookState["isDetailModal"]>
    ) => {
      state.isDetailModal = payload;
    },

    setCheckDate: (
      state,
      { payload }: PayloadAction<bookState["checkDate"]>
    ) => {
      state.checkDate = payload;
    },
  },
});

export const bookInfoSelector = createSelector(
  [(state: RootState) => state.book.bookInfo],
  (bookInfo) => bookInfo
);

export const dateRangeSelector = createSelector(
  [(state: RootState) => state.book.dateRanges],
  (dateRanges) => dateRanges
);

export const dateSelectSelector = createSelector(
  [(state: RootState) => state.book.dateSelects],
  (dateSelects) => dateSelects
);

export const timeSelectSelector = createSelector(
  [(state: RootState) => state.book.timeSelects],
  (timeSelects) => timeSelects
);

export const stepSelector = createSelector(
  [(state: RootState) => state.book.step],
  (step) => step
);

export const rmPckSelector = createSelector(
  [(state: RootState) => state.book.bookInfo.rmPck],
  (rmPck) => rmPck
);

export const isPackageModalSelector = createSelector(
  [(state: RootState) => state.book.isPackageModal],
  (isPackageModal) => isPackageModal
);

export const isDetailModalSelector = createSelector(
  [(state: RootState) => state.book.isDetailModal],
  (isDetailModal) => isDetailModal
);

export const isSearchSelector = createSelector(
  [(state: RootState) => state.book.isSearch],
  (isSearch) => isSearch
);

export const isBookSelector = createSelector(
  [(state: RootState) => state.book.isBook],
  (isBook) => isBook
);

export const rmOPListSelector = createSelector(
  [(state: RootState) => state.book.rmOpList],
  (rmOpList) => rmOpList
);

export const isBookSheetFullSelector = createSelector(
  [(state: RootState) => state.book.isBookSheetFull],
  (isBookSheetFull) => isBookSheetFull
);

export const checkDateSelector = createSelector(
  [(state: RootState) => state.book.checkDate],
  (checkDate) => checkDate
);

export const {
  setBookInfo,
  setResetBookInfo,
  setIsBookSheetFull,
  setCheckDate,
  setResetDate,
  setTimeSelect,
  setIsSearch,
  setStep,
  setrmPck,
  setIsPackageModal,
  setIsDetailModal,
  setIsBook,
  setRmOpList,
} = bookSlice.actions;

export default bookSlice.reducer;
