import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface mypageState {
  mngStep: 1 | 2 | 3 | 4 | number;
  isOpenPwdChk: boolean;
}

const initialState: mypageState = {
  mngStep: 1,
  isOpenPwdChk: false,
};

export const mypageSlice = createSlice({
  name: "mypage",
  initialState,
  reducers: {
    setManageStep: (
      state,
      { payload }: PayloadAction<mypageState["mngStep"]>
    ) => {
      state.mngStep = payload;
    },
    setIsOpenPwd: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenPwdChk = payload;
    },
  },
});

export const mngStepSelector = createSelector(
  [(state: RootState) => state.mypage.mngStep],
  (mngStep) => mngStep
);

export const isOpenPwdChkSelector = createSelector(
  [(state: RootState) => state.mypage.isOpenPwdChk],
  (isOpenPwdChk) => isOpenPwdChk
);

export const { setManageStep, setIsOpenPwd } = mypageSlice.actions;

export default mypageSlice.reducer;
