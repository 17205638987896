import React, { Suspense, lazy, useEffect } from "react";
import "./App.css";
import "assets/fonts/font.scss";

import { useLocation } from "react-router-dom";
import PageLoading from "component/layout/loading";
import { useTranslation } from "react-i18next";

const App: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();

  const MyComponent = lazy(async () => {
    return Promise.all([
      import("./router"),
      new Promise((resolve) => setTimeout(resolve, 500)),
    ]).then(([modulsExport]) => modulsExport);
  });

  useEffect(() => {
    if (!localStorage.getItem("lng")) {
      i18n.changeLanguage("kr");
      localStorage.setItem("lng", "kr");
    }
    return () => {
      localStorage.removeItem("lng");
    };
  }, []);
  return (
    <Suspense fallback={<PageLoading />}>
      <MyComponent />
    </Suspense>
  );
};

export default App;
