import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { nofiProps } from "interface";

export interface commState {
  userInfo: object | null;
  notiInfo: nofiProps;
  isLogin: boolean;
  isLoading: boolean;
  isOpenPhoneSelect: boolean;
  isOpenSelect: boolean;
  language: string;
  lodInfo: {
    lodNo: string;
    lodNm: string;
    hpUrl: string;
    rmCnt: number | null;
    adltCnt: number | null;
    chldCnt?: number | null;
    chkInTi: string;
    chkOutTi: string;
    promCd?: string;
    babyAgeStndV: string;
    chldAgeStndV: string;
  };
  option: {
    name: string;
    value: string;
  };
  isLoginModal: {
    isOpen: boolean;
    nextPath: string;
    isOutClose?: boolean;
  };
  isPageLoading: boolean;
  isBtnLoading: boolean;
  bottomSheetMove: number;
}

const initialState: commState = {
  userInfo: null,
  isLogin: false,
  isLoading: false,
  isPageLoading: true,
  language: "",
  lodInfo: {
    lodNo: "",
    lodNm: "",
    hpUrl: "",
    rmCnt: null,
    adltCnt: null,
    chldCnt: null,
    chkInTi: "",
    chkOutTi: "",
    promCd: "",
    babyAgeStndV: "",
    chldAgeStndV: "",
  },
  notiInfo: {
    message: null,
    title: "안내",
    btnText: "확인",
    zIndex: `z-[99]`,
    isConfirm: false,
    isCancel: false,
  },
  isOpenPhoneSelect: false,
  isOpenSelect: false,

  option: {
    name: "",
    value: "",
  },
  isLoginModal: {
    isOpen: false,
    nextPath: "",
    isOutClose: false,
  },
  isBtnLoading: false,
  bottomSheetMove: 0,
};

export const commSlice = createSlice({
  name: "comm",
  initialState,
  reducers: {
    setUserInfo: (state, { payload }: PayloadAction<object | null>) => {
      state.userInfo = payload;
    },

    setMessage: (state, { payload }: PayloadAction<nofiProps>) => {
      state.notiInfo = {
        ...state.notiInfo,
        message: payload.message,
        title: payload.title ?? "안내",
        zIndex: payload.zIndex,
        btnText: payload.btnText,
        isCancel: payload.isCancel,
        isConfirm: payload.isConfirm,
        handleClick: payload.handleClick,
        handleCancel: payload.handleCancel,
      };
    },

    setIsLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.isLogin = payload;
    },
    setLodInfo: (state, { payload }: PayloadAction<commState["lodInfo"]>) => {
      state.lodInfo = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setOpenPhoneSelect: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenPhoneSelect = payload;
    },
    setLoginModal: (
      state,
      { payload }: PayloadAction<commState["isLoginModal"]>
    ) => {
      state.isLoginModal = {
        isOpen: payload.isOpen,
        nextPath: payload.nextPath,
        isOutClose: payload.isOutClose,
      };
    },

    setIsBtnLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isBtnLoading = payload;
    },

    setBottomSheetMove: (state, { payload }: PayloadAction<number>) => {
      state.bottomSheetMove = payload;
    },
    setIsPageLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isPageLoading = payload;
    },
    setLanguage: (state, { payload }: PayloadAction<commState["language"]>) => {
      state.language = payload;
    },
  },
});

export const isLoginSelector = createSelector(
  [(state: RootState) => state.comm.isLogin],
  (session) => session
);

export const isLoadingSelector = createSelector(
  [(state: RootState) => state.comm.isLoading],
  (isLoading) => isLoading
);

export const messageSelector = createSelector(
  [(state: RootState) => state.comm.notiInfo],
  (notiInfo) => notiInfo
);

export const userInfoSelector = createSelector(
  [(state: RootState) => state.comm.userInfo],
  (userInfo) => userInfo
);

export const languageSelector = createSelector(
  [(state: RootState) => state.comm.language],
  (language) => language
);

export const lodInfoSelector = createSelector(
  [(state: RootState) => state.comm.lodInfo],
  (lodInfo) => lodInfo
);

export const isOpenPhoneSelectSelector = createSelector(
  [(state: RootState) => state.comm.isOpenPhoneSelect],
  (isOpenPhoneSelect) => isOpenPhoneSelect
);

export const isLoginModalSelector = createSelector(
  [(state: RootState) => state.comm.isLoginModal],
  (isLoginModal) => isLoginModal
);

export const isBtnLoadingSelector = createSelector(
  [(state: RootState) => state.comm.isBtnLoading],
  (isBtnLoading) => isBtnLoading
);

export const bottomSheetMoveSelector = createSelector(
  [(state: RootState) => state.comm.bottomSheetMove],
  (bottomSheetMove) => bottomSheetMove
);

export const isPageLoadingSelector = createSelector(
  [(state: RootState) => state.comm.isPageLoading],
  (isPageLoading) => isPageLoading
);

export const {
  setUserInfo,
  setMessage,
  setLodInfo,
  setIsLogin,
  setLanguage,
  setIsLoading,
  setOpenPhoneSelect,
  setLoginModal,
  setIsBtnLoading,
  setBottomSheetMove,
  setIsPageLoading,
} = commSlice.actions;

export default commSlice.reducer;
