import Home from "./home.json";
import Header from "./header.json";
import Login from "./login.json";
import Footer from "./footer.json";
import SignUp from "./signup.json";
import Book from "./book.json";
import Mypage from "./mypage.json";
import Alert from "./alert.json";
import Comm from "./comm.json";
const en = {
  ...Home,
  ...Header,
  ...Footer,
  ...Login,
  ...SignUp,
  ...Book,
  ...Mypage,
  ...Alert,
  ...Comm,
};

export default en;
