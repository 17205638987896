import "./loading.scss";
import Loading from "component/view/loading";

type IProps = {
  loading?: boolean;
};

const PageLoading = ({ loading }: IProps) => {
  return (
    <div className={"page-loading"}>
      <Loading />
    </div>
  );
};

export default PageLoading;
