import { createSlice, createSelector, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { authAgrList } from "interface";

export interface memberState {
  userInfo: object | null;

  isLogin: string | null;
  step: {
    step: number;
    guideWidth?: string;
    descript?: {
      txt: string;
      eTxt: string;
    };
  };
  allCheck: boolean;
  itemCheck: {
    langCd: "kr" | "en";
    prvGrpCd: string;
    prvSeq: string;
    required: boolean;
    prvAgYn: "Y" | "N";
    prvNm: string;
  }[];
  receiveCheck: authAgrList;
  option: {
    name: string;
    value: string;
  };
  reqNum: string;
}

const initialState: memberState = {
  userInfo: null,
  isLogin: null,
  step: {
    step: 0,
    guideWidth: "w-[7.5rem]",
    descript: {
      txt: "서비스 이용약관에 동의해주세요.",
      eTxt: "Please agree to the Terms of Service.",
    },
  },
  allCheck: false,
  itemCheck: [],
  receiveCheck: {
    authSubCd: "MkT001",
    agrYn: false,
  },
  reqNum: "",
  option: {
    name: "",
    value: "",
  },
};

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setUserInfo: (state, { payload }: PayloadAction<object | null>) => {
      state.userInfo = payload;
    },
    setAllCheck: (
      state,
      { payload }: PayloadAction<memberState["allCheck"]>
    ) => {
      state.allCheck = payload;

      const itemCheck = current(state.itemCheck);

      const newItemChecked = itemCheck.map((item) => {
        return { ...item, required: payload };
      });

      state.itemCheck = newItemChecked;
      localStorage.setItem("prvList", JSON.stringify(newItemChecked));
    },

    setItemCheck: (
      state,
      { payload }: PayloadAction<{ id: string; checked: boolean }>
    ) => {
      const { id, checked } = payload;

      const itemCheck = current(state.itemCheck);

      const newItemChecked = itemCheck.map((item) => {
        return item.prvGrpCd === id ? { ...item, required: checked } : item;
      });

      const isCheck = newItemChecked.filter((term) => term.required === true);
      if (isCheck.length === itemCheck.length) {
        state.allCheck = true;
      }
      if (isCheck.length !== itemCheck.length) {
        state.allCheck = false;
      }

      state.itemCheck = newItemChecked;
      localStorage.setItem("prvList", JSON.stringify(newItemChecked));
    },

    setDefaultItemCheck: (
      state,
      { payload }: PayloadAction<memberState["itemCheck"]>
    ) => {
      state.itemCheck = payload;
    },

    setReceiveCheck: (
      state,
      { payload }: PayloadAction<memberState["receiveCheck"]>
    ) => {
      state.receiveCheck = {
        authSubCd: payload.authSubCd,
        agrYn: payload.agrYn,
      };
    },
    setReqNum: (state, { payload }: PayloadAction<string>) => {
      state.reqNum = payload;
    },

    setSignStep: (state, { payload }: PayloadAction<memberState["step"]>) => {
      const descript = [
        {
          txt: "서비스 이용약관에 동의해주세요.",
          eTxt: "Please agree to the Terms of Service.",
        },
        {
          txt: "로그인에 아이디로 사용할 이메일주소를 입력해주세요.",
          eTxt: "Please enter the email address you will use as your ID to log in.",
        },
        {
          txt: "로그인에 사용할 비밀번호를 입력해주세요.",
          eTxt: "Please enter the password you will use to log in.",
        },
        {
          txt: "이름과 휴대전화 번호를 입력하세요.",
          eTxt: "Please enter your name and mobile phone number.",
        },
      ];

      state.step = {
        step: payload.step,
        descript: descript[payload.step - 1],
      };
    },
    setSession: (state, { payload }: PayloadAction<string | null>) => {
      state.isLogin = payload;
      payload
        ? localStorage.setItem("session", payload)
        : localStorage.removeItem("session");
    },
  },
});

export const isLoginSelector = createSelector(
  [(state: RootState) => state.member.isLogin],
  (session) => session
);

export const allCheckSelector = createSelector(
  [(state: RootState) => state.member.allCheck],
  (allCheck) => allCheck
);

export const itemCheckSelector = createSelector(
  [(state: RootState) => state.member.itemCheck],
  (itemCheck) => itemCheck
);

export const receiveCheckSelector = createSelector(
  [(state: RootState) => state.member.receiveCheck],
  (receiveCheck) => receiveCheck
);

export const reqNumSelector = createSelector(
  [(state: RootState) => state.member.reqNum],
  (reqNum) => reqNum
);

export const userInfoSelector = createSelector(
  [(state: RootState) => state.member.userInfo],
  (userInfo) => userInfo
);

export const signStepSelector = createSelector(
  [(state: RootState) => state.member.step],
  (guide) => guide
);

export const {
  setUserInfo,
  setAllCheck,
  setItemCheck,
  setReceiveCheck,
  setReqNum,
  setSession,
  setSignStep,
  setDefaultItemCheck,
} = memberSlice.actions;

export default memberSlice.reducer;
