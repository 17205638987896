import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import commSlice from "./commSlice";
import homeSlice from "./homeSlice";
import memberSlice from "./memSlice";
import bookSlice from "./bookSlice";
import mypageSlice from "./mypageSlice";
// import checkSlice from "./checkboxSlice";
// import memberSlice from "./memberSlice";

const store = configureStore({
  reducer: {
    comm: commSlice,
    home: homeSlice,
    member: memberSlice,
    book: bookSlice,
    mypage: mypageSlice,
  },
  middleware: getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
