import { createSlice, createSelector, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
// import { RooteState } from './store'

export interface homeState {
  dateRanges: string[];
  dateSelects: string[];
  timeSelects: string[];
}

const initialState: homeState = {
  dateRanges: [],
  dateSelects: [],
  timeSelects: [],
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setTimeSelect: (state, { payload }: PayloadAction<string>) => {
      const times = current(state.timeSelects);
      const dpTimes = times.filter((time) => {
        return time !== payload;
      });

      state.timeSelects =
        dpTimes.length === times.length ? [...times, payload] : dpTimes;
    },
  },
});

export const dateRangeSelector = createSelector(
  [(state: RootState) => state.home.dateRanges],
  (dateRanges) => dateRanges
);

export const dateSelectSelector = createSelector(
  [(state: RootState) => state.home.dateSelects],
  (dateSelects) => dateSelects
);

export const timeSelectSelector = createSelector(
  [(state: RootState) => state.home.timeSelects],
  (timeSelects) => timeSelects
);

export const { setTimeSelect } = homeSlice.actions;

export default homeSlice.reducer;
