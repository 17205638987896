import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import * as en from "./en";
import * as kr from "./kr";
import * as jp from "./jp";

const lng = localStorage.getItem("lng") || "kr";

const resources: Resource = {
  en: {
    translation: {
      ...en.default,
    },
  },
  kr: {
    translation: {
      ...kr.default,
    },
  },
  jp: {
    translation: {
      ...jp.default,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: `${lng}`,
  fallbackLng: "kr",
});

export default i18n;
