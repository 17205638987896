import "./loading.scss";

type IProps = {
  wrpStyle?: string;
  isSmall?: boolean;
};

export default function Loading({ wrpStyle, isSmall = false }: IProps) {
  return (
    <div className={`${wrpStyle} lds-wrap ${isSmall ? "small" : ""}`}>
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
